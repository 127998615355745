import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DEFAULT_LANGUAGE } from '@lib/constants';
import { AuthService, LanguageService, SidebarService, ThemeService } from '@lib/services';
import { AppLanguage, AppTheme } from '@lib/types';
import { Subject, takeUntil } from 'rxjs';
import { LanguageSwitcherComponent } from '../language-switcher/language-switcher.component';
import { ThemeSwitcherComponent } from '../theme-switcher/theme-switcher.component';

@Component({
    selector: 'app-sidebar',
    standalone: true,
    templateUrl: './sidebar.component.html',
    imports: [CommonModule, RouterModule, ThemeSwitcherComponent, LanguageSwitcherComponent],
})
export class SidebarComponent implements OnInit {
    emailstring = 'mailto:hai@tenangaja.co.id?Subject=Feedback%20for%20Nesya&body=';

    currentTheme!: AppTheme | null;
    currentLanguage: AppLanguage = DEFAULT_LANGUAGE;
    private readonly _router = inject(Router);
    private readonly _authService = inject(AuthService);
    private readonly _sidebarService = inject(SidebarService);
    private readonly _themeService = inject(ThemeService);
    private readonly _languageService = inject(LanguageService);

    private readonly _destroy$ = new Subject();

    closeSidebar(): void {
        this._sidebarService.close();
    }

    ngOnInit(): void {
        this._languageService.currentLanguage$
            .pipe(takeUntil(this._destroy$))
            .subscribe((language) => (this.currentLanguage = language));
        const browserDetection = navigator.userAgent;
        if (browserDetection.includes('iPhone')) {
            document.getElementById('sideBar')?.classList.add('pb-20');
        } else {
            document.getElementById('sideBar')?.classList.remove('pb-20');
        }
        this._themeService.currentTheme$
            .pipe(takeUntil(this._destroy$))
            .subscribe((theme) => (this.currentTheme = theme));
    }

    handleThemeChange(theme: AppTheme): void {
        this._themeService.setTheme(theme);
    }

    handleLanguageChange(language: AppLanguage): void {
        this._languageService.setLanguage(language);
    }

    onClickSignOut(): void {
        this.closeSidebar();
        this._authService.logout();
        this._router.navigate(['/']);
    }
}
