<div class="bg-white border-2 border-zinc-200 dark:bg-gray-700 rounded-r-xl rounded-tl-xl">
    <form
        [formGroup]="ratingForm"
        (ngSubmit)="submitFeedback()"
    >
        <div *ngIf="!isSubmitted; else submitted">
            <div class="text-center p-9">
                <p class="font-semibold mb-5">{{ 'FEEDBACK.TITLE' | translate }}</p>

                <div class="text-4xl mb-7">
                    <div class="flex justify-center space-x-6">
                        <span
                            *ngFor="let emoji of emojis; let i = index"
                            (click)="selectRating(i)"
                            [class.grayscale]="selectedRating !== i && selectedRating !== -1"
                            class="relative group justify-center cursor-pointer"
                            aria-hidden="true"
                        >
                            {{ emoji }}

                            <span
                                class="absolute bottom-[-30px] left-1/2 transform -translate-x-1/2 text-sm group-hover:opacity-100 transition-opacity duration-300"
                                [class.opacity-0]="selectedRating !== i"
                            >
                                {{ labels[i] | translate }}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <div class="relative w-full">
                    <textarea
                        autocomplete="off"
                        formControlName="feedbackMessage"
                        placeholder="{{ 'FEEDBACK.SHARE_YOUR_FEEDBACK' | translate }}"
                        class="flex text-sm w-full border-0 rounded-br-xl max-h-40 resize-none bg-zinc-200 dark:bg-gray-700 focus:outline-none pl-4 !pr-10 h-10"
                        (keydown)="onKeydown($event)"
                        appTextareaAutoresize
                    ></textarea>
                    <button
                        class="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-600 hover:text-gray-800"
                    >
                        <span class="text-2xl icon-[lets-icons--send]"></span>
                    </button>
                </div>
            </div>
        </div>
        <ng-template #submitted>
            <div class="flex items-center justify-center bg-gray-50">
                <div class="max-w-sm p-6 bg-white border rounded-lg shadow-md">
                    <div class="flex items-center justify-center mb-4">
                        <span class="text-[#3B82F6] text-5xl icon-[hugeicons--thumbs-up]"></span>
                    </div>
                    <p class="font-semibold text-center text-lg text-gray-700">You rated the conversation</p>
                    <p class="text-center text-sm text-gray-500 mt-2">Thanks for letting us know</p>
                </div>
            </div>
        </ng-template>
    </form>
</div>
