import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppTheme } from '@lib/types';

@Component({
    selector: 'app-theme-switcher',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './theme-switcher.component.html',
})
export class ThemeSwitcherComponent {
    @Input() currentTheme!: string | null;
    @Output() changeTheme = new EventEmitter<AppTheme>();

    toggleTheme(): void {
        const theme: AppTheme = this.currentTheme === 'dark' ? 'light' : 'dark';
        this.changeTheme.emit(theme);
    }
}
