import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ModalComponent } from '@lib/components';
import { TextareaAutoresizeDirective } from '@lib/directives/textarea-autoresize/textarea-autoresize.directive';
import { ChatService } from '@lib/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-rating',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ModalComponent,
        ReactiveFormsModule,
        TextareaAutoresizeDirective,
        TranslateModule,
    ],
    templateUrl: './rating.component.html',
})
export class RatingComponent {
    private readonly _fb = inject(FormBuilder);
    private readonly _chatService = inject(ChatService);
    private readonly _translate = inject(TranslateService);

    ratingForm = this._fb.group({
        rate: [0, [Validators.required]],
        feedbackMessage: ['', []],
    });

    emojis: string[] = ['😡', '😞', '😐', '😊', '😍']; // Emojis for rating
    labels: string[] = ['FEEDBACK.BAD', 'FEEDBACK.POOR', 'FEEDBACK.AVERAGE', 'FEEDBACK.GREAT', 'FEEDBACK.AMAZING']; // Labels under emojis
    selectedRating = -1; // Tracks selected rating
    isSubmitted = false;

    selectRating(index: number): void {
        this.selectedRating = index;
        this.setRate(this.selectedRating + 1);
    }

    onKeydown(event: KeyboardEvent): void {
        // console.log(event);
        if (event.key === 'Enter') {
            event.preventDefault();
            this.submitFeedback();
        }
    }

    submitFeedback(): void {
        if (this.selectedRating === -1) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: <string>this._translate.instant('FEEDBACK.VALIDATION.RATING.REQUIRED'),
            });
            return;
        }
        // console.log('feedback request: ', this.ratingForm.value);
        // Send the feedback to your backend or perform further actions as needed
        this._chatService
            .rateSession({
                ratings: this.ratingForm.value.rate,
                feedbackMessage: this.ratingForm.value.feedbackMessage,
            })
            .subscribe((response) => {
                console.log('feedback response: ', response);
                this.isSubmitted = true;
            });
    }

    setRate(rating: number): void {
        this.ratingForm.patchValue({
            rate: rating,
        });
    }
}
