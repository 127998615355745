/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
    selector: '[appTextareaAutoresize]',
    standalone: true,
})
export class TextareaAutoresizeDirective implements OnInit {
    constructor(private _elementRef: ElementRef) {}

    @HostListener(':input')
    onInput(): void {
        this.resize();
    }

    ngOnInit(): void {
        if (this._elementRef.nativeElement.scrollHeight) {
            setTimeout(() => this.resize());
        }
    }

    resize(): void {
        this._elementRef.nativeElement.style.height = '0';
        this._elementRef.nativeElement.style.height = this._elementRef.nativeElement.scrollHeight + 'px';
    }
}
